





































































import {Component, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import draggable from 'vuedraggable';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

@Component({
  components: {
    StembleLatex,
    ChemicalLatex,
    draggable,
  },
})
export default class DragToRankInputFr extends Vue {
  protected clonedInput: string[] = [];
  protected clonedItems: string[] = [];
  protected justEmitted: boolean = false;

  @Model('input')
  protected readonly value!: string[] | null;

  @Prop({default: 'Highest'})
  protected readonly prependText!: string;

  @Prop({default: () => []})
  protected readonly items!: string[];

  @Prop({default: 'Lowest'})
  protected readonly appendText!: string;

  @Prop({default: false})
  protected readonly disabled!: boolean;

  created() {
    this.cloneInputs();
  }

  @Watch('value')
  protected valueChanged() {
    if (!this.justEmitted) {
      this.cloneInputs();
    }
    this.justEmitted = false;
  }

  @Watch('items')
  protected itemsChanged() {
    this.cloneInputs();
  }

  protected cloneInputs() {
    this.clonedItems = this.items ? JSON.parse(JSON.stringify(this.items)) : [];
    this.clonedInput = this.value ? JSON.parse(JSON.stringify(this.value)) : [];

    for (const inputValue of this.clonedInput) {
      const indexInItems = this.clonedItems.indexOf(inputValue);
      if (indexInItems !== -1) {
        this.clonedItems.splice(indexInItems, 1);
      }
    }
  }

  protected syncInput() {
    this.justEmitted = true;
    this.$emit('input', this.clonedInput);
  }
}
